import React from 'react';
import { MainSection } from 'views/our-partners/components/main-section';
import { ServicesSection } from 'views/our-partners/components/services-section';

export const OurPartners = () => {
    return (
        <>
            <MainSection />;
            <ServicesSection />
        </>
    );
};
