import React from 'react';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import Robomaticon from 'svgs/our-partners/robomaticon.svg';
import Soda from 'svgs/our-partners/soda.svg';

export const useOurPartnersData = () => {
    const data = useStaticQuery(graphql`
        {
            creativeBrains: file(
                relativePath: { eq: "our-partners/creative-brains.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        width: 390
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return [
        {
            id: 'soda',
            title: 'our-partners.partners.soda.title',
            description: 'our-partners.partners.soda.description',
            alt: 'our-partners.partners.soda.imageAlt',
            url: EXTERNAL_REDIRECTS.SODA_URL,
            isExternalLink: true,
            img: <Soda />,
            order: 'even',
            graphicsWrapperCustomStyles: { alignItems: 'center' },
        },
        {
            id: 'creative-brains',
            title: 'our-partners.partners.creativeBrains.title',
            description: 'our-partners.partners.creativeBrains.description',
            alt: 'our-partners.partners.creativeBrains.imageAlt',
            url: EXTERNAL_REDIRECTS.CREATIVE_BRAINS_URL,
            isExternalLink: true,
            img: data.creativeBrains,
            isReversed: true,
            order: 'even',
            graphicsWrapperCustomStyles: {
                alignItems: 'center',
                height: '12.2rem',
            },
        },
        {
            id: 'robomaticon',
            title: 'our-partners.partners.robomaticon.title',
            description: 'our-partners.partners.robomaticon.description',
            alt: 'our-partners.partners.robomaticon.imageAlt',
            url: EXTERNAL_REDIRECTS.ROBOMATICON_URL,
            isExternalLink: true,
            img: <Robomaticon />,
            order: 'even',
            graphicsWrapperCustomStyles: { alignItems: 'center' },
        },
        {
            id: 'backlinks',
            title: 'our-partners.partners.backlinks.title',
            description: 'our-partners.partners.backlinks.description',
            url: PATHS.BACKLINKS,
            img: null,
            order: 'even',
        },
    ];
};
