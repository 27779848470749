import React from 'react';
import { OurServicesSection } from 'modules/our-services-section';

import { useOurPartnersData } from '../../hooks/use-our-partners';

export const ServicesSection = () => {
    const data = useOurPartnersData();

    return (
        <OurServicesSection
            data={data}
            wrapperCustomStyles={{ marginTop: 0 }}
        />
    );
};
